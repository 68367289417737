import http from '../../services/http';
import {
    toRef,
    reactive
} from 'vue'

const store = reactive({
    plants: []
})

function getAll() {
    return new Promise((resolve, reject) => {
        http.core.get('/data/items/Inventaire_Plants?sort[]=LatinName')
            .then(({
                data
            }) => {
                store.plants = data.data
                resolve(data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

function getValueSearch(value) {
    return new Promise((resolve, reject) => {
        http.core.get(`/data/items/Inventaire_Plants?search=${value}&sort[]=LatinName&fields=Varieties.related_Inventaire_Plants_id.*,*`)
            .then(({
                data
            }) => {
                resolve(data)
            })
            .catch(err => {
                reject(err)
            })
    })

}

export function usePlantService() {
    return {
        plants: toRef(store, 'plants'),
        getAll,
        getValueSearch
    }
}