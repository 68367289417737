<template>
  <div>
    <Panel>
      <template #header>
        <h1 class="text-5xl font-light">{{ i18n.$t("inventories") }}</h1>
      </template>

      <!-- <Message v-if="!!hasError" severity="error" :closable="false">{{ hasError }}</Message> -->

      <Sidebar v-model:visible="visibleRight" class="p-sidebar-md" position="right">
        <div v-if="currentPlant">
          <div class="flex flex-column m-3 mb-5">
            <Image :src="getPreview(currentPlant.Preview)" alt="Image" preview />
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon icon="tabler:plant-2" class="mr-3" width="24px" height="24px" />
              <div>
                <span>{{ currentPlant.Name }}</span
                ><br />
                <span class="text-sm font-semibold">{{ currentPlant.LatinName }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon icon="lucide:type" class="mr-3" width="24px" height="24px" />
              <div>
                <span class="text-sm font-semibold">{{ i18n.$t("type") }}</span
                ><br />
                <span>{{ i18n.$t(currentPlant.Type) }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon icon="bi:box" class="mr-3" width="24px" height="24px" />
              <div>
                <span class="text-sm font-semibold">{{ i18n.$t("flower_form") }}</span
                ><br />
                <span>{{ i18n.$t(currentPlant.FlowerForm) }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon icon="carbon:soil-moisture" class="mr-3" width="24px" height="24px" />
              <div>
                <span class="text-sm font-semibold mb-1">{{ i18n.$t("soil_type") }}</span
                ><br />
                <Chip
                  class="mr-2 mb-2"
                  v-for="(t, index) in currentPlant.SoilType"
                  :key="index"
                >
                  <span class="text-sm my-1">{{ i18n.$t(t) }}</span>
                </Chip>
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon icon="mdi:ph" class="mr-3" width="24px" height="24px" />
              <div>
                <span class="text-sm font-semibold mb-1">{{ i18n.$t("soil_ph") }}</span
                ><br />
                <Chip
                  class="mr-2 mb-2"
                  v-for="(t, index) in currentPlant.SoilPh"
                  :key="index"
                >
                  <span class="text-sm my-1">{{ i18n.$t(t) }}</span>
                </Chip>
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon icon="bi:moisture" class="mr-3" width="24px" height="24px" />
              <div>
                <span class="text-sm font-semibold">{{ i18n.$t("soil_moisture") }}</span
                ><br />
                <Chip
                  class="mr-2"
                  v-for="(t, index) in currentPlant.SoilMoisture"
                  :key="index"
                >
                  <span class="text-sm my-1">{{ i18n.$t(t) }}</span>
                </Chip>
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon
                icon="emojione-monotone:blossom"
                class="mr-3"
                width="24px"
                height="24px"
              />
              <span v-for="month in currentPlant.BlossomPeriod" :key="month">
                <Chip
                  label="Text"
                  v-if="currentPlant.BlossomPeriod"
                  class="py-1 mr-1 mb-1"
                  :color="months[month].color"
                >
                  <span class="flex justify-content-center align-items-center">
                    <Icon
                      :icon="months[month].icon"
                      :color="months[month].color"
                      width="14"
                      height="14"
                    />
                    <span class="ml-2 text-sm">{{ i18n.$t(months[month].tag) }}</span>
                  </span>
                </Chip>
              </span>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon
                icon="carbon:soil-moisture-global"
                class="mr-3"
                width="24px"
                height="24px"
              />
              <div v-for="month in currentPlant.PlantingPeriod" :key="month">
                <Chip
                  label="Text"
                  v-if="currentPlant.PlantingPeriod"
                  class="py-1 mr-1 mb-1"
                  :color="months[month].color"
                >
                  <span class="flex justify-content-center align-items-center">
                    <Icon
                      :icon="months[month].icon"
                      :color="months[month].color"
                      width="14"
                      height="14"
                    />
                    <span class="ml-2 text-sm">{{ i18n.$t(months[month].tag) }}</span>
                  </span>
                </Chip>
              </div>
            </div>
          </div>
          <div class="flex justify-content-around mx-3 mb-4">
            <div
              class="flex flex-column align-items-center"
              v-for="s in currentPlant.SunExposure"
              :key="s"
            >
              <Icon v-if="s === 'full-sun'" icon="emojione:sun" width="20" height="20" />
              <Icon
                v-if="s === 'partial-shade'"
                icon="emojione-v1:sun-behind-cloud"
                width="20"
                height="20"
              />
              <Icon
                v-if="s === 'full-shade'"
                icon="emojione-v1:cloud"
                width="20"
                height="20"
              />
              <span class="text-sm">{{ i18n.$t(s) }}</span>
            </div>
          </div>
        </div>
      </Sidebar>

      <DataTable
        v-if="plants"
        :value="plants"
        :paginator="true"
        class="p-datatable-plants z-4"
        :rows="20"
        dataKey="id"
        :rowHover="true"
        v-model:selection="selectedPlants"
        v-model:filters="filters"
        filterDisplay="menu"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[20, 35, 50]"
        :currentPageReportTemplate="
          i18n.$t('d_padination', {
            first: first,
            last: last,
            totalRecords: totalRecords,
          })
        "
        :globalFilterFields="['Name', 'LatinName', 'BlossomPeriod']"
        responsiveLayout="scroll"
        @row-click="getDetail($event)"
      >
        <template #header>
          <div class="flex justify-content-between align-items-center">
            <h5 class="m-0">{{ i18n.$t("plants") }}</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="searchValue"
                v-on:change="globalSearch"
                :placeholder="i18n.$t('search')"
              />
            </span>
          </div>
        </template>
        <template #empty> No Plants found. </template>
        <template #loading> Loading plants data. Please wait. </template>
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="Name" :header="i18n.$t('name')" sortable>
          <template #body="{ data }">
            <div class="flex align-items-center">
              <img class="avatar-img mr-2" :src="getPreview(data.Preview)" />
              <span class="text-base">{{ data.Name }}</span>
            </div>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>
        <Column
          field="LatinName"
          :header="i18n.$t('latin_name')"
          sortable
          filterMatchMode="contains"
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            <span class="text-base">{{ data.LatinName }}</span>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by latin name"
            />
          </template>
        </Column>
        <Column
          :header="i18n.$t('blossom_period')"
          filterField="BlossomPeriod"
          :showFilterMatchModes="false"
          :filterMenuStyle="{ width: '10rem' }"
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            <span v-for="month in data.BlossomPeriod" v-bind:key="`${month}-${data.id}`">
              <Chip
                label="Text"
                v-if="data.BlossomPeriod"
                class="py-1 mr-1 mb-1"
                :color="months[month].color"
              >
                <span class="flex justify-content-center align-items-center">
                  <Icon
                    :icon="months[month].icon"
                    :color="months[month].color"
                    width="14"
                    height="14"
                  />
                  <span class="ml-2 text-sm">{{ i18n.$t(months[month].tag) }}</span>
                </span>
              </Chip>
            </span>
          </template>
          <template #filter="{ filterModel }">
            <div class="mb-3 font-bold">Period Picker</div>
            <MultiSelect
              v-model="filterModel.value"
              :options="months"
              optionLabel="id"
              placeholder="Any"
              class="p-column-filter"
            >
              <template #option="slotProps">
                <Chip label="Text" class="py-1 mr-1 mb-1">
                  <span class="flex justify-content-center align-items-center">
                    <Icon
                      :icon="slotProps.option.icon"
                      :color="slotProps.option.color"
                      width="14"
                      height="14"
                    />
                    <span class="ml-2 text-sm">{{ i18n.$t(slotProps.option.tag) }}</span>
                  </span>
                </Chip>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column
          :header="i18n.$t('planting_period')"
          filterField="PlantingPeriod"
          :showFilterMatchModes="false"
          :filterMenuStyle="{ width: '10rem' }"
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            <span v-for="month in data.PlantingPeriod" v-bind:key="`${month}-${data.id}`">
              <Chip
                label="Text"
                v-if="data.PlantingPeriod"
                class="py-1 mr-1 mb-1"
                :color="months[month].color"
              >
                <span class="flex justify-content-center align-items-center">
                  <Icon
                    :icon="months[month].icon"
                    :color="months[month].color"
                    width="14"
                    height="14"
                  />
                  <span class="ml-2 text-sm">{{ i18n.$t(months[month].tag) }}</span>
                </span>
              </Chip>
            </span>
          </template>
          <template #filter="{ filterModel }">
            <div class="mb-3 font-bold">Period Picker</div>
            <MultiSelect
              v-model="filterModel.value"
              :options="months"
              optionLabel="id"
              placeholder="Any"
              class="p-column-filter"
            >
              <template #option="slotProps">
                <Chip label="Text" class="py-1 mr-1 mb-1">
                  <span class="flex justify-content-center align-items-center">
                    <Icon
                      :icon="slotProps.option.icon"
                      :color="slotProps.option.color"
                      width="14"
                      height="14"
                    />
                    <span class="ml-2 text-sm">{{ i18n.$t(slotProps.option.tag) }}</span>
                  </span>
                </Chip>
              </template>
            </MultiSelect>
          </template>
        </Column>
      </DataTable>
    </Panel>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import router from "@/router";
import { useProcess } from "../../compositions/useProcess";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { usePlantService } from "../../compositions/services/usePlantService";
import { useI18n } from "../../../src/i18nPlugin";
import usePreview from "../../compositions/usePreview";
import { useToast } from "primevue/usetoast";

export default {
  setup() {
    const { getPreview } = usePreview();
    const toast = useToast();
    const { plants } = usePlantService();
    const { getAll } = usePlantService();
    const { getValueSearch } = usePlantService();
    const currentPlant = ref();
    const searchValue = ref();
    const selectedPlants = ref();
    const visibleRight = ref(false);
    // const plantService = new PlantService();
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      LatinName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      BlossomPeriod: { value: null, matchMode: FilterMatchMode.IN },
      PlantingPeriod: { value: null, matchMode: FilterMatchMode.IN },
    });
    const loading = ref(true);
    const months = [
      { id: 0, tag: " " },
      { id: 1, tag: "january", icon: "iconoir:snow-flake", color: "#628DDC" },
      { id: 2, tag: "fevrier", icon: "iconoir:snow-flake", color: "#628DDC" },
      { id: 3, tag: "march", icon: "ph:flower-lotus-fill", color: "#D54E6F" },
      { id: 4, tag: "april", icon: "ph:flower-lotus-fill", color: "#D54E6F" },
      { id: 5, tag: "may", icon: "ph:flower-lotus-fill", color: "#D54E6F" },
      { id: 6, tag: "june", icon: "bxs:leaf", color: "#289D31" },
      { id: 7, tag: "july", icon: "bxs:leaf", color: "#289D31" },
      { id: 8, tag: "august", icon: "bxs:leaf", color: "#289D31" },
      {
        id: 9,
        tag: "septembre",
        icon: "emojione-monotone:fallen-leaf",
        color: "#D4640F",
      },
      {
        id: 10,
        tag: "october",
        icon: "emojione-monotone:fallen-leaf",
        color: "#D4640F",
      },
      {
        id: 11,
        tag: "november",
        icon: "emojione-monotone:fallen-leaf",
        color: "#D4640F",
      },
      { id: 12, tag: "december", icon: "iconoir:snow-flake", color: "#628DDC" },
    ];
    const i18n = useI18n();

    getAll(searchValue.value)
      .then(() => {
        loading.value = false;
      })
      .catch((err) => {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: "3000",
        });
      });

    function isRunningRow(data) {
      if (data.hasError) {
        return "has-error";
      }
      loading.value = false;
      return data.state === "running" ? "is-running" : null;
    }

    function globalSearch() {
      loading.value = false;
      getValueSearch(searchValue.value)
        .then(() => {
          router.push(`?search=${searchValue.value}`);
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: "3000",
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }

    function getDetail(event) {
      visibleRight.value = true;
      currentPlant.value = event.data;
    }

    return {
      i18n,
      isRunningRow,
      globalSearch,
      ...useProcess(),
      plants,
      searchValue,
      currentPlant,
      filters,
      loading,
      getPreview,
      getDetail,
      visibleRight,
      selectedPlants,
      months,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.is-running) {
  background-color: rgba(19, 160, 0, 0.15) !important;
}
::v-deep(.has-error) {
  background-color: rgba(160, 0, 0, 0.137) !important;
}

img {
  vertical-align: middle;
}
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

::v-deep(.p-image) {
  img {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
  }
  .p-image-preview-indicator {
    height: 200px;
    border-radius: 8px;
  }
}

::v-deep(.p-datatable.p-datatable-plants) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
    position: relative;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
