import {
  ref
} from 'vue'
// import { useWebSocket } from '@vueuse/core'

import K from '../constants'
import mitt from 'mitt'

class Wsck {
  constructor() {
    this.status = ref('')

    this.url = ''
    this.sock = null
    this.emitter = mitt()
    this.pinger = null

    const token = localStorage.getItem('pxtoken')
    if (!token) {
      console.error('Wsck: Empty Token')
      return
    }
    this.url = `${K.HUB_WS_URL}?token=${token}`
    console.log(this.url)
  }

  onOpen(self) {
    self.status.value = statusToLabel(WebSocket.OPEN)
    self.emitter.emit(this.buildKey('wsck', 'status'), {
      status: self.status.value
    })
  }

  onClose(self) {
    self.status.value = statusToLabel(WebSocket.CLOSED)
    self.emitter.emit(this.buildKey('wsck', 'status'), {
      status: self.status.value
    })
  }

  onMessage(self, event) {
    try {
      const msg = JSON.parse(event.data)
      if (msg.header.event === 'ping') return
      self.emitter.emit(this.buildKey(msg.header.service, msg.header.event), msg.payload)
    } catch (e) {
      console.error(e)
    }
  }

  subscribe(service, event, cb) {
    this.emitter.on(this.buildKey(service, event), cb)
  }

  buildKey(service, event) {
    return `${service}:${event}`
  }

  connect() {
    if (!this.sock || this.sock.readyState >= WebSocket.CLOSING) {
      if (!this.url) {
        console.error('[websocket.ts] Call generateWsUrl before connect')
        return
      }
      const self = this
      this.sock = new WebSocket(this.url)
      this.sock.onmessage = function (event) {
        self.onMessage(self, event)
      }
      this.sock.onopen = function (event) {
        self.onOpen(self, event)
      }
      this.sock.onclose = function (event) {
        self.onClose(self, event)
      }
      self.status.value = statusToLabel(WebSocket.CONNECTING)
      self.emitter.emit(this.buildKey('wsck', 'status'), {
        status: self.status.value
      })
    }
    setTimeout(this.connect.bind(this), 2000)

    if (!this.pinger) {
      const self = this
      self.pinger = setInterval(() => {
        self.ping(self)
      }, 10000)
    }
  }

  ping(self) {
    self.send('wsck', 'ping', {})
  }

  send(service, event, payload) {
    if (this.sock && this.sock.readyState === WebSocket.OPEN) {
      this.sock.send(JSON.stringify({
        header: {
          service,
          event
        },
        payload
      }))
    }
  }
}

const wsck = new Wsck()

export function useWsckSetup() {
  wsck.connect()

  return {
    status: wsck.status
  }
}

export function useWsck() {
  return {
    status: wsck.status,
    sendTo: wsck.send
  }
}

export function useWsckOn(service, event, cb) {
  wsck.subscribe(service, event, cb)
}

function statusToLabel(status) {
  switch (status) {
    case WebSocket.CONNECTING:
      return 'CONNECTING'
    case WebSocket.OPEN:
      return 'OPEN'
    case WebSocket.CLOSING:
      return 'CLOSING'
    case WebSocket.CLOSED:
      return 'CLOSED'
  }
}