import { ref, reactive } from 'vue'

import http from '../services/http'
import { useWsckOn } from './useWsck'

export function useProcess () {
  const isLoading = ref(true)
  const hasError = ref(null)

  const list = reactive([])
  const byId = reactive({})

  useWsckOn('process', 'process.state:updated', function (data) {
    console.log('process.state:updated', data, byId)
    if (byId[data.id]) {
      byId[data.id].state = data.state
      byId[data.id].startedAt = data.startedAt
      byId[data.id].lastError = data.lastError
      byId[data.id].nbRestarts = data.nbRestarts
      byId[data.id].streamState = data.streamState
      byId[data.id].hasError = ''
    } else {
      byId[data.id] = data
      list.push(data)
    }
  })

  // http.core
  // .get('/director/processes')
  // .then(({data}) => {
  //   data.list.forEach(one => {
  //     byId[one.id] = one
  //     list.push(one)
  //   })
  // })
  // .catch(err => {
  //   hasError.value = err.response ? err.response.data : err
  // })
  // .finally(() => {
  //   isLoading.value = false
  // })

  function startProc (id) {
    http.core
    .get(`/director/processes/${id}/start`)
    .then(() => {})
    .catch(err => {
      if (byId[id]) {
        byId[id].hasError = err.response ? err.response.data : err
      }
    })
  }

  function stopProc (id) {
    http.core
    .get(`/director/processes/${id}/stop`)
    .then(() => {})
    .catch(err => {
      if (byId[id]) {
        byId[id].hasError = err.response ? err.response.data : err
      }
    })
  }

  function playProc (id) {
    http.core
    .get(`/director/processes/${id}/play`)
    .then(() => {})
    .catch(err => {
      if (byId[id]) {
        byId[id].hasError = err.response ? err.response.data : err
      }
    })
  }
  
  function pauseProc (id) {
    http.core
    .get(`/director/processes/${id}/pause`)
    .then(() => {})
    .catch(err => {
      if (byId[id]) {
        byId[id].hasError = err.response ? err.response.data : err
      }
    })
  }
  
  return {
    isLoading,
    hasError,
    list,

    startProc,
    stopProc,
    playProc,
    pauseProc
  }
}