const HUB_BASE_URL = '/core'
const HUB_BASE_WS = `${HUB_BASE_URL}/ws`
const HUB_HOST = window.location.host
const HUB_WS_PROTOCOL = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

const K = {
  HUB_HTTP_URL: HUB_BASE_URL,
  HUB_WS_URL: `${HUB_WS_PROTOCOL}//${HUB_HOST}${HUB_BASE_WS}`
}
console.log('host      :', HUB_HOST)
console.log('protocol  :', HUB_WS_PROTOCOL)
console.log('PXHUB URL :', K)

export default K