function getPreview(preview, height, quality) {
  return `/core/data/assets/${preview}?height=${height || 1024}&quality=${quality || 100}`;
}

// function getPreview2(preview, quality) {
//   return `http://${window.location.host}/core/data/assets/${preview}?quality=${quality}`;
// }
export default function usePreview() {
  return {
    getPreview,
    // getPreview2
  };
}